import cx from 'classnames';
import React, { ReactElement } from 'react';
import Markdown from 'react-markdown';
import { ISectionData } from 'typings/generated/contentful';

interface Props {
  sectionData?: ISectionData;
  classNames?: {
    preHeader?: string;
    mainHeader?: string;
    divider?: string;
    description?: string;
  };
}

function SectionTitle({ sectionData, classNames }: Props): ReactElement | null {
  if (!sectionData) {
    return null;
  }

  const { preHeader, mainHeader, description } = sectionData.fields;
  return (
    <div className="text-center">
      {preHeader && (
        <div className={cx('text-lg', classNames?.preHeader)}>{preHeader}</div>
      )}
      {mainHeader && (
        <h2
          className={cx(
            'mt-4 max-w-2xl m-auto text-4xl font-bold',
            classNames?.mainHeader
          )}
        >
          {mainHeader}
        </h2>
      )}
      <div
        className={cx(
          'w-32 m-auto h-px mt-8',
          classNames?.divider || 'bg-bg-dark'
        )}
      />
      {description && (
        <Markdown
          className={cx(
            'max-w-4xl mt-8 text-lg rich-text',
            classNames?.description
          )}
        >
          {description}
        </Markdown>
      )}
    </div>
  );
}

export default SectionTitle;
