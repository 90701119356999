import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ICta } from 'typings/generated/contentful';

import Chevron from '../svg/chevron';
import ContentfulRichText from './contentful-rich-text';

interface Props {
  button?: ICta;
  className?: string;
  smaller?: boolean;
  marginTop?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  progress?: boolean;
  centered?: boolean;
  type?: 'submit' | 'button';
}

function CTAButton({
  button,
  smaller,
  className,
  disabled = false,
  progress = false,
  centered = true,
  type = 'button',
}: Props): ReactElement | null {
  if (!button) {
    return null;
  }
  return (
    <div
      className={cx(
        'flex flex-col justify-center',
        { 'items-center': centered },
        className
      )}
    >
      {/* button */}
      <a href={button.fields.link}>
        <button
          className={cx(
            'relative px-12 pr-16 overflow-hidden max-w-xs w-full font-bold text-white uppercase rounded-lg outline-none shadow-cta font-display bg-cta-primary focus:outline-none',
            {
              'py-6 text-xl': !smaller,
              'py-4': smaller,
              'animate-pulse': !disabled && button.fields.animated,
              'cursor-not-allowed': !progress && disabled,
              'cursor-wait': progress,
            }
          )}
          disabled={disabled}
          style={{ filter: disabled ? 'grayscale(1)' : undefined }}
          type={type}
        >
          {button.fields.copy}
          <span className="absolute top-0 right-0 flex items-center h-full px-4 bg-cta-secondary">
            <Chevron />
          </span>
        </button>
      </a>
      {/* subtitle */}
      <div
        className={cx('flex items-center  mt-4', {
          'justify-center': !button.fields.subImage,
          hidden: !button.fields.subText,
        })}
      >
        {button.fields.subImage && (
          <GatsbyImage
            className="w-24 h-8 mr-4 contain"
            fluid={getFluidImage(button.fields.subImage, {
              q: 100,
              w: null,
              fm: null,
            })}
          />
        )}
        <span>
          <ContentfulRichText document={button.fields.subText} />
        </span>
      </div>
    </div>
  );
}

export default CTAButton;
