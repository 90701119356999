import cx from 'classnames';
import React, { ReactElement } from 'react';

interface Props {
  className?: string;
}

function Chevron({ className }: Props): ReactElement {
  return (
    <svg
      className={cx('fill-current', className)}
      fill="none"
      height="27"
      viewBox="0 0 14 27"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.6552 12.9887C13.8465 13.1832 13.8465 13.4953 13.6552 13.6899L1.35647 26.1942C1.04271 26.5132 0.5 26.291 0.5 25.8436V0.835008C0.5 0.387554 1.04271 0.165386 1.35647 0.484396L13.6552 12.9887Z" />
    </svg>
  );
}

export default Chevron;
